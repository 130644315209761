import React, { createContext } from 'react'
import axios, { AxiosResponse } from 'axios'
import { setCookie, getCookie } from '../../../utils/cookie'
import { ILocation, ILatLng } from '../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/interfaces'
import { GEOCODING_KEY, GOOGLE_GEOCODING_ENDPOINT, GOOGLE_GEOCODING_US_ENDPOINT } from '../../../settings/variables'
import { DEFAULT_STORE_CODES, ILocationCookieType } from '../../StoreLocator/interfaces'
import { GERS_API_CALLS } from '../Services/GERS'
import { IStoreCodes } from '../Services/GERS/Pricing/storelocator'
import { CMS_API_CALLS } from '../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Services/API/CMS'
import getDefaultCmsTransport from '../../../services/api/cmsInterceptor'
import { IStoreLocation } from '../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Locations/interfaces'
import { getDistanceToStore } from '../../Utils/locationUtils'
import { findProp, roundCoordinates } from '../../../services/api/appUtils'

// -----------------------------------------------------------------------CONTEXT-----------------------------------------------------------

export interface ILocationContextProviderProps {
  location: ILocation | null
  storeList: IStoreLocation[] | null
  setLocationByZip: (zipCode: string) => any
  setLocationByZipAutofill: (zipCode: string) => any
  getClosestStore: () => void
  myClosestStore: IStoreLocation | null | undefined
  setMyStore: (store: IStoreLocation) => Promise<void>
  getLocationFromZip: (zipCode: string) => Promise<any>
  sortStoresByDistance: () => void
  storeCodes: IStoreCodes
  showLocationSelect: boolean
  setShowLocationSelect: (locationSelect: boolean) => void
  setClosestWarehouse: (store: IStoreLocation) => void
  closestWarehouse: IStoreCodes | undefined
  setWarehouse: (store: IStoreLocation) => void
  warehouse: IStoreLocation | undefined
}

const LocationContext = createContext<ILocationContextProviderProps | null>(null)

// ------------------------------------------------------------------------ CLASS ------------------------------------------------------

interface IIncomingProps {
  location: ILocation | null
  storeList: IStoreLocation[] | null
  myClosestStore: IStoreLocation | null | undefined
}

interface IState {
  location: ILocation | null
  storeList: IStoreLocation[] | null
  myClosestStore: IStoreLocation | null | undefined
  storeCodes: any
  showLocationSelect: boolean
  closestWarehouse: IStoreCodes | undefined
  warehouse: IStoreLocation | undefined
}

class LocationContextProvider extends React.Component<IIncomingProps, IState> {
  constructor(props: IIncomingProps) {
    super(props)
    const existingCookie: string | undefined = getCookie(ILocationCookieType.STORE_CODES, null)
    let codes: IStoreCodes = DEFAULT_STORE_CODES
    if (existingCookie) {
      codes = JSON.parse(unescape(existingCookie)) as IStoreCodes
    }
    this.state = {
      location: props.location,
      storeList: props.storeList || [],
      myClosestStore: undefined,
      closestWarehouse: undefined,
      storeCodes: codes,
      showLocationSelect: false,
      warehouse: undefined,
    }
  }

  componentDidUpdate = async (prevProps: IIncomingProps) => {
    if (this.props.myClosestStore !== prevProps.myClosestStore) {
      this.setState({ myClosestStore: this.props.myClosestStore })
    }
    if (this.props.location !== prevProps.location) {
      this.setState({ location: this.props.location })
    }
  }

  public sortStoresByDistance = () => {
    if (this.state.storeList) {
      const existingLocationCookieString: string | undefined = getCookie(ILocationCookieType.GEOLOCATION, null)
      if (existingLocationCookieString) {
        const location = JSON.parse(unescape(existingLocationCookieString)) as ILocation
        const locationLatLng = location ? location.latLng : null

        this.state.storeList.sort((a: IStoreLocation, b: IStoreLocation) => {
          const distanceA = getDistanceToStore(locationLatLng, {
            lat: a.latitude,
            lng: a.longtitude,
          })

          const distanceB = getDistanceToStore(locationLatLng, {
            lat: b.latitude,
            lng: b.longtitude,
          })

          return distanceA - distanceB
        })
      }
    }
  }

  public setLocationByZip = async (zipCode: string, showCustomError?: boolean) => {
    let location: AxiosResponse = await axios.post(
      `${GOOGLE_GEOCODING_US_ENDPOINT}&address=${zipCode}&key=${GEOCODING_KEY}`,
    )
    if (location.data.results.length > 0) {
      if (location.data.results[0].partial_match) {
        const partialMatchLocation = await axios.post(
          `${GOOGLE_GEOCODING_ENDPOINT}?address=${zipCode}&key=${GEOCODING_KEY}`,
        )
        if (partialMatchLocation?.data?.results?.length > 0) {
          location = await axios.post(`${GOOGLE_GEOCODING_ENDPOINT}?address=${zipCode}&key=${GEOCODING_KEY}`)
        }
      }
      const state: string = findProp('administrative_area_level_1', location.data.results)
      const city: string = findProp('locality', location.data.results) || findProp('political', location.data.results)
      let latLng: ILatLng = location.data.results[0].geometry.location
      latLng = {
        lat: roundCoordinates(latLng.lat),
        lng: roundCoordinates(latLng.lng),
      }
      if (city && state) {
        setCookie(ILocationCookieType.GEOLOCATION, { city: city, state: state, zip: zipCode, latLng })
        this.setState({ location: { city: city, state: state, zip: zipCode, latLng: latLng } })
      }
      let codes = DEFAULT_STORE_CODES
      const response: any = await GERS_API_CALLS.STORELOCATOR.getClosestStoreInfo(zipCode)
      if (response.error) {
        setCookie(ILocationCookieType.STORE_CODES, codes)
        this.setState({ storeCodes: codes })
      }
      if (response.data) {
        codes = { storeCode: response.data.storeCode || 'XX', warehouseCode: response.data.warehouseCode || 'WX' }
        setCookie(ILocationCookieType.STORE_CODES, codes)
        this.setState({ storeCodes: codes })
      }
    } else {
      if (showCustomError) {
        return {
          error: true,
          message: "sorry, couldn't find that zip code",
        }
      }
      alert("sorry, couldn't find that zip code")
    }

    await this.getClosestStore()
    return null
  }

  public setLocationByZipAutofill = async (zipCode: string) => {
    let location: AxiosResponse = await axios.post(
      `${GOOGLE_GEOCODING_US_ENDPOINT}&address=${zipCode}&key=${GEOCODING_KEY}`,
    )
    if (location.data.results.length > 0) {
      if (location.data.results[0].partial_match) {
        location = await axios.post(`${GOOGLE_GEOCODING_ENDPOINT}?address=${zipCode}&key=${GEOCODING_KEY}`)
      }
      const state: string = findProp('administrative_area_level_1', location.data.results)
      const city: string = findProp('locality', location.data.results) || findProp('political', location.data.results)
      if (city && state) {
        return { city: city, state: state, zip: zipCode }
      }
    }
    return {
      error: true,
      message: "sorry, couldn't find that zip code",
    }
  }

  public getLocationFromZip = async (zipCode: string) => {
    let location: AxiosResponse = await axios.post(
      `${GOOGLE_GEOCODING_US_ENDPOINT}&address=${zipCode}&key=${GEOCODING_KEY}`,
    )
    if (location.data.results.length > 0) {
      if (location.data.results[0].partial_match) {
        location = await axios.post(`${GOOGLE_GEOCODING_ENDPOINT}?address=${zipCode}&key=${GEOCODING_KEY}`)
      }
      return location.data.results[0].geometry.location
    }
    return {
      lat: this.state.myClosestStore?.latitude,
      lng: this.state.myClosestStore?.longtitude,
    }
  }

  public setMyStore = async (store: IStoreLocation) => {
    //set user location from store's info
    const state: string | undefined = store.state
    const city: string | undefined = store.city
    const zip: string | undefined = store.zip
    const latLng: ILatLng = {
      lat: roundCoordinates(store.latitude),
      lng: roundCoordinates(store.longtitude),
    }
    setCookie(ILocationCookieType.GEOLOCATION, { city: city, state: state, zip: zip, latLng: latLng })
    this.setState({ myClosestStore: store })

    let codes = DEFAULT_STORE_CODES
    const response: any = await GERS_API_CALLS.STORELOCATOR.getClosestStoreInfo(zip)
    if (response.error) {
      setCookie(ILocationCookieType.STORE_CODES, codes)
      this.setState({ storeCodes: codes })
    }
    if (response.data) {
      codes = { storeCode: response.data.storeCode || 'XX', warehouseCode: response.data.warehouseCode || 'WX' }
      setCookie(ILocationCookieType.STORE_CODES, codes)
      this.setState({ storeCodes: codes })
    }

    if (state && city && zip) {
      this.setState({ location: { city: city, state: state, zip: zip, latLng: latLng } })
    }
  }

  public getClosestStore = async () => {
    const existingCookie: string | undefined = getCookie(ILocationCookieType.STORE_CODES, null)
    if (existingCookie) {
      const codes: IStoreCodes = JSON.parse(unescape(existingCookie)) as IStoreCodes
      if (codes.storeCode && codes.storeCode !== 'XX') {
        const response: any = await CMS_API_CALLS.LOCATIONS.getSingleLocation(
          getDefaultCmsTransport(true),
          codes.storeCode,
        )
        if (response.error) {
          return null
        } else {
          this.setState({ myClosestStore: response.data[0] })
          return response.data[0]
        }
      } else {
        this.setState({ myClosestStore: null })
      }
    }
  }

  public setShowLocationSelect = (locationSelect: boolean) => {
    this.setState({ showLocationSelect: locationSelect })
  }

  public setClosestWarehouse = async (store: IStoreLocation) => {
    let codes: IStoreCodes = DEFAULT_STORE_CODES
    const response: any = await GERS_API_CALLS.STORELOCATOR.getClosestStoreInfo(store.zip)
    if (!response.error && response.data) {
      codes = { storeCode: response.data.storeCode || 'XX', warehouseCode: response.data.warehouseCode || 'WX' }
    }
    this.setState({ closestWarehouse: codes })
  }

  public setWarehouse = async (store: IStoreLocation) => {
    this.setState({ warehouse: store })
  }

  public render() {
    const { children } = this.props
    const exportedValues: ILocationContextProviderProps = {
      location: this.state.location,
      setLocationByZip: this.setLocationByZip,
      setLocationByZipAutofill: this.setLocationByZipAutofill,
      getClosestStore: this.getClosestStore,
      myClosestStore: this.state.myClosestStore,
      storeList: this.state.storeList,
      setMyStore: this.setMyStore,
      getLocationFromZip: this.getLocationFromZip,
      sortStoresByDistance: this.sortStoresByDistance,
      storeCodes: this.state.storeCodes,
      showLocationSelect: this.state.showLocationSelect,
      setShowLocationSelect: this.setShowLocationSelect,
      setClosestWarehouse: this.setClosestWarehouse,
      closestWarehouse: this.state.closestWarehouse,
      setWarehouse: this.setWarehouse,
      warehouse: this.state.warehouse,
    }

    return <LocationContext.Provider value={exportedValues}>{children}</LocationContext.Provider>
  }
}

export default {
  Context: LocationContext,
  Consumer: LocationContext.Consumer,
  Provider: LocationContextProvider,
}
