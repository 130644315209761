import React, { useState, useEffect, useRef, RefObject } from 'react'
import { Row, Col } from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Layout'
import { find } from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/lodash'
import css from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/PDP/PDP.module.scss'
import {
  IProduct,
  IProductAttributeField,
  IPDPLayoutConfigurationRow,
  IProductGroup,
  IAttribute,
} from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/PDP'
import { FIELD_DISPLAY_TYPES } from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Interfaces/Strapi/Product'
import { IPDPLayout as IPDPLayoutAlias } from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Interfaces/Strapi/Product'
import { MERCE_CLASS_NAMES } from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/interfaces'
import { IStoreCodes } from '../../Services/GERS/Pricing/storelocator'
import VerticalCarousel from './VerticalCarousel/VerticalCarousel'
import CylindoViewer, {
  getStandardProductFeatures,
  ICylindoCustomFeatureCodes,
  ICylindoViewerInstance,
  isImage360Viewer,
  supportedFeatureCodes,
} from './CylindoViewer/CylindoViewer'
import Swatches, { IAttributeSwatch, ISwatchOption } from './Swatches/Swatches'
import { useRouter } from 'next/router'
import { IMorUnbxdProduct, IRelatedType } from '../../../Utils/unbxdUtils'
import commonCss from './Common.scss'
import {
  CUSTOM_ADDON_GROUP_CODE,
  cylindoCodes,
  generateCylindoProductUrl,
  getCustomProductColorValue,
  getCustomProductCylindoMaterialCd,
  getSelectedOptionsInitialState,
  isCustomProduct,
} from '../../../Utils/customProductUtils'
import ColorWheel from '../Common/Images/ColorWheel'
import { ITransport } from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Services/API/CMS'
import { getProductProps } from '../../../../modules/products/productQuery'
import { CYLINDO_VIEWER_ID } from '../../../../settings/variables'
import useOnScreen from '../../../Utils/hooks/useOnScreen'
import { destroyCylindoViewerInstance } from './CylindoViewer/cylindoUtils'
import ChatJumpLink from './ChatJumpLink/ChatJumpLink'
import { getBreadcrumbString } from '../../../../utils/pages/pagesUtil'

interface IPDPConfiguration {
  layout?: IPDPLayoutConfigurationRow[]
  renderProps: {
    [x: string]: (product: IProduct, isPositionFixed?: boolean) => JSX.Element
  }
  defaultTransport?: ITransport
  defaultImage?: string
  methods: {
    addItemToCart: (targetProduct: IProduct, quantity: number) => void
    navigateToProduct: (product: IProduct, persistQueryParams?: boolean) => void
  }
}

export interface IPDPProduct extends IMorUnbxdProduct {
  relatedItems?: IProduct[]
  RELATED_ITM_CD_LIST?: string[]
  groupSwatches?: IAttributeSwatch[]
}

export interface IPDProps {
  product: IPDPProduct
  configuration: IPDPConfiguration
  horizontal?: boolean
  usePriceDivider?: boolean
  storeCodes?: IStoreCodes
  instance?: any
  onChangeSelectedCustomAddons: (selectedOptions: ICustomActiveOptions[]) => void
  onChangeStandardOptions: (selectedOptions: IActiveOptions[]) => void
}

export type IPDPLayout = IPDPLayoutAlias

export interface ICustomFieldOption {
  selected: boolean
  label: string
  product: IProduct
  [x: string]: any
}

export interface ICustomActiveOptions extends IActiveOptions {
  customOptionValueId: number
}

export interface IActiveOptions {
  combine?: string
  value: any
  code: string
}

export enum ICustomAddons {
  BODY_COLOR = 'BODY_COLOR',
  ACCENT_COLOR = 'ACCENT_COLOR',
  ACCENT_COLOR2 = 'ACCENT_COLOR2',
  ACCENT_COLOR3 = 'ACCENT_COLOR3',
}

const orderedFields = [
  'COLOR',
  'BODY_COLOR',
  'ACCENT_COLOR',
  'ACCENT_COLOR2',
  'ACCENT_COLOR3',
  'SEAT_COMP',
  'FACING',
  'BED_SIZE',
  'ADJ_FOUNDATION',
  'BED_FOUND_NEED',
  'MATTRESS_COMFORT_LVL',
  'MOTION_TYPE',
  'POWER_TYPE',
  'SEAT_BACK_TYPE',
  'SIZE_CLASS',
  'STORAGE_COMPARTMENT',
  'STORAGE_TYPE',
  'TABLE_HEIGHT_CLASS',
  'TABLE_SHAPE',
  'TV_SIZE',
]

const PDP = (props: IPDProps) => {
  const router = useRouter()

  const [selectedCustomAddons, setSelectedCustomAddons] = useState<ICustomActiveOptions[]>([])
  // init the related item with the custom or standard product from the server side.
  const [relatedItem, setRelatedItem] = useState(props.product.relatedItems?.[0])
  const [loadingRelatedItem, setLoadingRelatedItem] = useState<boolean>(false)
  const [isFacingOptionChanging, setIsFacingOptionChanging] = useState<boolean>(false)
  const [windowInnerWidth, setWindowInnerWidth] = useState<number>(0)

  const containerRef: RefObject<HTMLDivElement> = useRef(null)
  const containerVisible = useOnScreen(containerRef)

  useEffect(() => {
    if (window.innerWidth) {
      setWindowInnerWidth(window.innerWidth)
    }
  })

  useEffect(() => {
    const { product } = props
    if (isImage360Viewer(product) || product.IMAGE360 !== 'N') {
      let customAddonsInitialState: ICustomActiveOptions[] = []
      const standardOptionsInitialState: IActiveOptions[] = activeOptionsLookup.filter(op =>
        supportedFeatureCodes.includes(op.code),
      )

      if (product.CYLINDO_MATERIAL_CD) {
        const newColor = {
          code: cylindoCodes.COLOR,
          value: product.CYLINDO_MATERIAL_CD,
        }

        const foundIndex = standardOptionsInitialState.findIndex(op => op.code === cylindoCodes.COLOR)
        if (foundIndex !== -1) {
          standardOptionsInitialState[foundIndex] = newColor
        }
      }

      if (product.ACCENT_COLOR_STOCK_SKU) {
        const customProductCylindoMaterialCode = getCustomProductCylindoMaterialCd(product)
        const accentColor = customProductCylindoMaterialCode
          ? getCustomProductColorValue(customProductCylindoMaterialCode, product.ACCENT_COLOR_STOCK_SKU)
          : product.ACCENT_COLOR_STOCK_SKU
        standardOptionsInitialState.push({
          code: ICustomAddons.ACCENT_COLOR,
          value: accentColor,
        })
      }

      if (product.ACCENT_COLOR_STOCK_SKU2) {
        const customProductCylindoMaterialCode = getCustomProductCylindoMaterialCd(product)
        const accentColor = customProductCylindoMaterialCode
          ? getCustomProductColorValue(customProductCylindoMaterialCode, product.ACCENT_COLOR_STOCK_SKU2)
          : product.ACCENT_COLOR_STOCK_SKU2
        standardOptionsInitialState.push({
          code: ICustomAddons.ACCENT_COLOR2,
          value: accentColor,
        })
      }

      if (product.ACCENT_COLOR_STOCK_SKU3) {
        const customProductCylindoMaterialCode = getCustomProductCylindoMaterialCd(product)
        const accentColor = customProductCylindoMaterialCode
          ? getCustomProductColorValue(customProductCylindoMaterialCode, product.ACCENT_COLOR_STOCK_SKU3)
          : product.ACCENT_COLOR_STOCK_SKU3
        standardOptionsInitialState.push({
          code: ICustomAddons.ACCENT_COLOR3,
          value: accentColor,
        })
      }

      // init the selected options for custom products for the color swatches to be marked as selected
      if (isCustomProduct(product)) {
        customAddonsInitialState = getSelectedOptionsInitialState(product)
      }

      updateRelatedItem()
      setSelectedCustomAddons(customAddonsInitialState)
      props.onChangeSelectedCustomAddons(customAddonsInitialState)
      props.onChangeStandardOptions(standardOptionsInitialState)
    }
  }, [props.product])

  const uniqueSellingOptionsLookup: {
    [x: string]: ICustomFieldOption[]
  } = {}
  const activeOptionsLookup: Array<IActiveOptions> = []

  const addUniqueSellingOptions = (product: IProduct, group?: IProductGroup) => {
    for (const field of product.fields) {
      const allowedAttributes: IAttribute[] = group && group.attributes ? group.attributes : []
      const targetAttribute: IAttribute | undefined = find(allowedAttributes, {
        code: field.attribute?.code,
      })
      if (targetAttribute) {
        if (!uniqueSellingOptionsLookup[field.attribute.code]) {
          uniqueSellingOptionsLookup[field.attribute.code] = []
        }
        const existsAttribute: ICustomFieldOption = find(uniqueSellingOptionsLookup[field.attribute.code], {
          label: field.fieldValue,
        })
        if (!existsAttribute) {
          uniqueSellingOptionsLookup[field.attribute.code].push(({
            label: field.fieldValue,
            products: [product],
          } as unknown) as ICustomFieldOption)
        } else {
          existsAttribute.products.push(product)
        }

        // the items that will get highlighted
        if (product.id === props.product.id) {
          activeOptionsLookup.push({
            code: field.attribute.code,
            combine: `${field.attribute.code}:${field.fieldValue}`,
            value: field.fieldValue,
          })
        }
      }
    }
  }
  const initializeGroupData = (): IProductGroup | undefined | null => {
    const groupData: IProductGroup | undefined | null = props.product.productgroup
    if (groupData?.products?.length) {
      for (const product of groupData.products) {
        addUniqueSellingOptions(product, groupData)
      }
    } else {
      addUniqueSellingOptions(props.product, groupData)
    }
    return groupData && typeof groupData === 'string' ? null : groupData
  }

  const groupData = initializeGroupData()

  // --------------------------------------------------------------------- EVENTS ------------------------------------------

  const onSwatchChangeToProduct = (option: ICustomFieldOption | ISwatchOption) => {
    if (option.label === 'Right' || option.label === 'Left') {
      setIsFacingOptionChanging(true)
    }
    navigateToProduct(option.product)
  }

  const navigateToProduct = (targetProduct?: IProduct) => {
    if (targetProduct) {
      const currentProduct = props.product
      destroyCylindoViewerInstance(currentProduct, targetProduct)
      const persistQueryParams = location.search.length > 0 && isCustomProduct(currentProduct)
      props.configuration.methods.navigateToProduct(targetProduct, persistQueryParams)
    }
  }

  const mapOptionsToCustomFeatures = (options: IActiveOptions[]) => {
    const features: string[] = []
    options.forEach((element: IActiveOptions) => {
      if (element.code === ICustomAddons.ACCENT_COLOR) {
        features.push(ICylindoCustomFeatureCodes.ACCENT_PILLOW)
      } else if (element.code === ICustomAddons.ACCENT_COLOR2) {
        features.push(ICylindoCustomFeatureCodes.ACCENT_PILLOW2)
      } else if (element.code === ICustomAddons.ACCENT_COLOR3) {
        features.push(ICylindoCustomFeatureCodes.ACCENT_PILLOW3)
      } else {
        features.push(ICylindoCustomFeatureCodes.COLOR)
      }
      features.push(element.value.toUpperCase())
    })

    return features
  }

  /**
   * Gets the Cylindo instance if any, and sets the corresponding features given the selected options
   * on color Swatches and generates the custom url to persist the features as query params and be able to load the same
   * customized product if the user refreshes the page.
   */
  const customizeProduct = (option: ISwatchOption, code: string, customOptionValueId: number) => {
    const cylindoProductId = props.product?.variants?.[0]?.cylindoProductId || props.product?.cylindoProductId
    if (cylindoProductId) {
      const viewerInstance: ICylindoViewerInstance = cylindo.viewer.getInstances()[
        `${cylindoProductId}_${CYLINDO_VIEWER_ID}`
      ]
      const currentAddons = selectedCustomAddons

      // Then update the option with the new value if the option exist, else add it to the selected options.
      const existingOption = currentAddons?.find(op => op.code === code)
      const cylindoMaterialCd = getCustomProductCylindoMaterialCd(product)
      const optionLabel =
        (code === ICustomAddons.BODY_COLOR ||
          code === ICustomAddons.ACCENT_COLOR ||
          code === ICustomAddons.ACCENT_COLOR2 ||
          code === ICustomAddons.ACCENT_COLOR3) &&
        cylindoMaterialCd
          ? getCustomProductColorValue(cylindoMaterialCd, option.label)
          : option.label
      if (existingOption) {
        existingOption.value = optionLabel
        existingOption.customOptionValueId = customOptionValueId
      } else {
        currentAddons.push({ code, value: optionLabel, customOptionValueId })
      }

      console.log(currentAddons)

      setSelectedCustomAddons(currentAddons)
      props.onChangeSelectedCustomAddons(currentAddons)
      const standardFeatures = getStandardProductFeatures(activeOptionsLookup)
      const customFeatures = mapOptionsToCustomFeatures(currentAddons)

      if (viewerInstance) {
        viewerInstance.setFeatures(standardFeatures.concat(customFeatures))
      }

      router.push(`${location.pathname}?${generateCylindoProductUrl(selectedCustomAddons)}`, undefined, {
        shallow: true,
      })
    }
  }

  const onSelectChangeToProduct = (evt: any) => {
    if (evt.target.id === cylindoCodes.FACING) {
      setIsFacingOptionChanging(true)
    }
    const productId: string = evt.target.value
    if (groupData && groupData.products) {
      const targetProduct: IProduct | undefined = find(groupData.products, {
        id: productId,
      })
      navigateToProduct(targetProduct)
    }
  }

  // -------------------------------------------------------------------- UTILS -------------------------------------------

  const filterByProductAttributes = (
    options: ICustomFieldOption[],
    code: string,
  ): ICustomFieldOption[] | null | undefined => {
    const groupData: IProductGroup | undefined | null = props.product.productgroup
    const allowedAttributes: IAttribute[] = groupData?.attributes || []
    if (allowedAttributes.length) {
      const otherAttributesCodes = allowedAttributes
        .filter((attr: IAttribute) => {
          return attr.code !== code
        })
        .map(attr => attr.code)
      const productFields = props.product.fields.filter((field: IProductAttributeField) => {
        return otherAttributesCodes.includes(field.attribute.code)
      })

      return options?.filter((opt: ICustomFieldOption) => {
        const relatedProducts: IProduct[] = []

        opt.products.forEach((relatedProduct: IProduct) => {
          let fullMatch: boolean = true
          const relatedProductFields = relatedProduct.fields.filter((field: IProductAttributeField) => {
            return otherAttributesCodes.includes(field.attribute.code)
          })

          productFields.forEach((field: IProductAttributeField) => {
            const relatedField = relatedProductFields.find((otherField: IProductAttributeField) => {
              return otherField.attribute.code === field.attribute.code
            })

            if (relatedField?.fieldValue !== field.fieldValue) {
              fullMatch = false
            }
          })

          if (fullMatch) {
            relatedProducts.push(relatedProduct)
          }
        })

        if (relatedProducts.length) {
          opt.products = relatedProducts
          opt.product = relatedProducts[0]
          return true
        }
        return false
      })
    }
    return options
  }

  const getOptionsByAttribute = (code: string): ICustomFieldOption[] | null | undefined => {
    return filterByProductAttributes(uniqueSellingOptionsLookup[code], code)
  }

  const isOptionSelected = (code: string): boolean => {
    return (
      find(activeOptionsLookup, {
        combine: code,
      }) !== undefined
    )
  }

  const getDefaultValue = (options: ICustomFieldOption[], field: IProductAttributeField): string | undefined => {
    const selectedOption = options.find((option: ICustomFieldOption) => {
      return isOptionSelected(`${field.attribute.code}:${option.label}`)
    })

    return selectedOption?.product?.id
  }

  // --------------------------------------------------------------- RENDER -------------------------------------------------------------------------------

  const renderOptions = (type: string, field: IProductAttributeField) => {
    const options: ICustomFieldOption[] | null | undefined = getOptionsByAttribute(field.attribute.code)
    const { groupSwatches } = props.product
    const isSwatchType = groupSwatches?.find(attr => attr.code === field.attribute.code)

    if (!options || (options?.length <= 1 && !isSwatchType)) {
      return <div style={{ marginLeft: '10px' }}>{field.fieldValue}</div>
    }
    if (isSwatchType) {
      // if the attribute code match with the a swatch code we use the swatches display type. This is done to keep supporting dropdowns.
      type = FIELD_DISPLAY_TYPES.SWATCHES
    }

    switch (type) {
      case FIELD_DISPLAY_TYPES.DROPDOWN:
      case FIELD_DISPLAY_TYPES.BOXES:
        return (
          <select
            id={field?.attribute?.code}
            style={{ padding: '3px' }}
            className={css.dropdownOptions}
            onChange={onSelectChangeToProduct}
            value={getDefaultValue(options, field)}
          >
            {options.map((option: ICustomFieldOption, index: number) => {
              return (
                <option
                  value={option.product.id}
                  key={index}
                  className={`${isOptionSelected(`${field.attribute.code}:${option.label}`) ? css.active : ''}`}
                >
                  {option.label}
                </option>
              )
            })}
          </select>
        )
      case FIELD_DISPLAY_TYPES.SWATCHES:
        if (field.attribute.group.code === CUSTOM_ADDON_GROUP_CODE && options) {
          return (
            <Swatches
              options={options}
              field={field}
              groupSwatches={groupSwatches}
              isOptionSelected={isOptionSelected}
              onClickSwatch={customizeProduct}
              selectedOptions={selectedCustomAddons}
              isCustomAddon
            />
          )
        }
        return (
          <Swatches
            options={options}
            field={field}
            groupSwatches={groupSwatches}
            isOptionSelected={isOptionSelected}
            onClickSwatch={onSwatchChangeToProduct}
          />
        )
      default:
        return <div id={field?.attribute?.code}>{field.fieldValue}</div>
    }
  }
  const renderFieldValue = (field: IProductAttributeField) => {
    const displayTypeCode: FIELD_DISPLAY_TYPES | undefined = field?.attribute?.displayType?.code
    if (displayTypeCode !== FIELD_DISPLAY_TYPES.STATIC && groupData) {
      return renderOptions(displayTypeCode, field)
    }
    return <div style={{ marginLeft: '15px' }}>{field.fieldValue}</div>
  }

  const renderField = (field: IProductAttributeField) => {
    let displayTypeCode: FIELD_DISPLAY_TYPES | undefined = field?.attribute?.displayType?.code

    const options: ICustomFieldOption[] | null | undefined = getOptionsByAttribute(field.attribute.code)
    const { groupSwatches } = props.product
    const isSwatchType = groupSwatches?.find(attr => attr.code === field.attribute.code)

    if (!options || (options?.length <= 1 && !isSwatchType)) {
      return <div style={{ marginLeft: '10px' }}>{field.fieldValue}</div>
    }
    if (isSwatchType) {
      // if the attribute code match with the a swatch code we use the swatches display type. This is done to keep supporting dropdowns.
      displayTypeCode = FIELD_DISPLAY_TYPES.SWATCHES
    }

    if (displayTypeCode === FIELD_DISPLAY_TYPES.SWATCHES) {
      const options: ICustomFieldOption[] | null | undefined = getOptionsByAttribute(field.attribute.code)
      if (options && options.length > 0) {
        return (
          <React.Fragment>
            <div>
              <b>{field.attribute.title}</b>:
            </div>
            <div className={css.fieldValueContainer}>{renderFieldValue(field)}</div>
          </React.Fragment>
        )
      }
    }
    return (
      <div className={commonCss.fieldContainer}>
        <div>
          <label htmlFor={field?.attribute?.code}>
            <b>{field.attribute.title}</b>
          </label>
          :
        </div>
        <div className={css.fieldValueContainer}>{renderFieldValue(field)}</div>
      </div>
    )
  }

  const reorderTargetFields = (targetFields: IProductAttributeField[]) => {
    const orderedTargetFields: IProductAttributeField[] = []
    orderedFields.forEach(fieldName => {
      const targetField = targetFields.find(field => field.attribute.code === fieldName)
      if (targetField) {
        orderedTargetFields.push(targetField)
      }
    })
    return orderedTargetFields
  }

  /**
   * Navigate to the related product and update the state with the current product to be able to go back,
   * either a standard or a custom product.
   */
  const navigateToRelatedProduct = () => {
    if (!loadingRelatedItem && relatedItem) {
      props.configuration.methods.navigateToProduct(relatedItem)
      setSelectedCustomAddons([])
      setRelatedItem(props.product)
    }
  }

  const updateRelatedItem = async () => {
    if (
      props.product?.RELATED_ITM_CD_LIST?.[0] &&
      relatedItem?.sku &&
      !props.product.RELATED_ITM_CD_LIST.includes(relatedItem?.sku)
    ) {
      setLoadingRelatedItem(true)
      const productPageProps = await getProductProps(props.product.RELATED_ITM_CD_LIST[0])
      if (productPageProps.product) {
        setRelatedItem(productPageProps.product)
      }
      setLoadingRelatedItem(false)
    }
  }

  /**
   * Gets the Product Group attributes (selling attributes) and the Product attributes (fields).
   * If there’s a match between the Product Group attributes and the Product Attributes, the attribute will be considered as a targetField
   * and it will be rendered on the right column on Product detail page.
   */
  const renderProductGroupAttributes = () => {
    const targetFields: IProductAttributeField[] = []
    if (groupData && groupData.attributes) {
      const allowedAttributes: IAttribute[] = groupData.attributes
      // get all attributes we are supposed to show
      const { fields } = props.product
      for (const field of fields) {
        const isAllowed: IAttribute | undefined = find(allowedAttributes, {
          code: field.attribute.code,
        })
        if (isAllowed) {
          if (field.attribute) {
            targetFields.push(field)
          }
        }
      }
    }
    if (!targetFields.length) {
      return <div />
    }
    return (
      <div className={`${MERCE_CLASS_NAMES.PDP_PRODUCT_SELLING_ATTRIBUTES}`}>
        <hr />
        {(isCustomProduct(props.product) ? reorderTargetFields(targetFields) : targetFields).map(
          (field: IProductAttributeField, index: number) => {
            return <div key={index}>{renderField(field)}</div>
          },
        )}
        <div className={commonCss.customizeContainer}>
          {props.product?.variants?.[0]?.RELATED_TYPE === IRelatedType.CUSTOMSKU && relatedItem && (
            <div className={`${commonCss.customizeLink} ${loadingRelatedItem && commonCss.disabled}`}>
              <ColorWheel />
              <div
                className={`${commonCss.customizeLabel} ${loadingRelatedItem && commonCss.disabled}`}
                onClick={navigateToRelatedProduct}
              >
                Customize
              </div>
            </div>
          )}
          {props.product?.variants?.[0]?.RELATED_TYPE === IRelatedType.INVSKU && relatedItem && (
            <div
              className={`${commonCss.customizeLink} ${loadingRelatedItem && commonCss.disabled}`}
              onClick={navigateToRelatedProduct}
            >
              <div className={`${commonCss.customizeLabel} ${loadingRelatedItem && commonCss.disabled}`}>
                Return to Preset Colors
              </div>
            </div>
          )}
          {loadingRelatedItem && <div className={commonCss.updatingItemsLabel}>Updating...</div>}
        </div>
        <hr />
      </div>
    )
  }

  const renderImageCore = () => {
    const { product, configuration, horizontal } = props
    return (
      <div className={css.contentContainer}>
        <div className={css.thumbnailCarousel}>
          {isImage360Viewer(product) ? (
            <CylindoViewer
              product={product}
              options={activeOptionsLookup}
              isFacingOptionChanging={isFacingOptionChanging}
              onFinishInitViewer={() => setIsFacingOptionChanging(false)}
            />
          ) : (
            <VerticalCarousel
              defaultImage={configuration ? configuration.defaultImage : 'https://via.placeholder.com/800x800'}
              sliderData={product.images.items}
              horizontal={horizontal}
            />
          )}
        </div>
      </div>
    )
  }
  const { product } = props
  const { renderProps } = props.configuration
  const collection = (product as any)?.collectionRoute?.[0]?.split('-')?.[0]?.toUpperCase()

  return (
    <div className={css.pdpContainer}>
      <Col sm={8} style={{ marginBottom: '15px' }}>
        <Row>
          <Col sm={12} style={{ marginBottom: '15px' }}>
            {renderImageCore()}
          </Col>
        </Row>
        {windowInnerWidth > 767 && (
          <div className={commonCss.desktopPdp}>
            <Row>{renderProps.renderProductDetailRow(product)}</Row>
          </div>
        )}
      </Col>
      <Col sm={4} style={{ marginBottom: '15px' }}>
        <Row>
          <Col sm={12}>{renderProps.renderTopProductInfo(product)}</Col>
        </Row>
        <Row>
          <Col sm={12} style={{ marginBottom: '15px' }}>
            {renderProductGroupAttributes()}
          </Col>
        </Row>
        <Row>
          <Col sm={12} style={{ marginBottom: '15px' }}>
            {renderProps.renderAvailability(product)}
          </Col>
        </Row>
        <Row>
          <Col sm={12} style={{ marginBottom: '15px' }}>
            <div ref={containerRef}>{renderProps.renderATCButton(product)}</div>
          </Col>
        </Row>
        <Row>
          <Col sm={12} style={{ marginBottom: '15px' }}>
            {renderProps.renderIncludes(product)}
          </Col>
        </Row>
        <Row>
          <Col sm={12} style={{ marginBottom: '15px' }}>
            {renderProps.renderDeliveryDetails()}
          </Col>
        </Row>
        {windowInnerWidth <= 767 && (
          <div className={commonCss.mobilePdp}>{renderProps.renderProductDetailRow(product)}</div>
        )}
      </Col>
      <Col sm={12} style={{ marginBottom: '15px' }}>
        {renderProps.renderProductsInCollection(product)}
      </Col>
      {product.categories && collection && (
        <Col sm={12}>
          <div
            data-crl8-container-id="category"
            data-crl8-filter={`category:'${getBreadcrumbString(product.categories)} > ${collection}'`}
          ></div>
        </Col>
      )}
      <Col sm={12} style={{ marginBottom: '15px' }}>
        {renderProps.renderUnbxdRecommendations(product)}
      </Col>
      {!containerVisible && (
        <div className={commonCss.fixedAtcButtonContainer}>{renderProps.renderATCButton(product, true)}</div>
      )}
    </div>
  )
}

export default PDP
