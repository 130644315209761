import axios from 'axios'

export const ENDPOINTS = {
  UNBXD_CATALOG: '/unbxd-catalog/',
  UNBXD_SEARCH: '/unbxd-search/',
  UNBXD_RECOMMENDATIONS: '/unbxd-recommendations/',
  UNBXD_PRODUCT: '/unbxd-product/',
}

const getDefaultTransport = (path?: any) => {
  if (path !== undefined) {
    if (typeof window === 'object') {
      const csrfToken = window.document.querySelector('meta[name="csrf-token"]')?.getAttribute('content')
      return axios.create({
        baseURL: path.baseURL,
        headers: { 'CSRF-Token': csrfToken },
      })
    }
    return axios.create(path)
  }
  if (typeof window === 'object') {
    const csrfToken = window.document.querySelector('meta[name="csrf-token"]')?.getAttribute('content')
    return axios.create({
      headers: { 'CSRF-Token': csrfToken },
    })
  }

  return axios
}

export default getDefaultTransport
