import getDefaultCmsTransport from '../../../../services/api/cmsInterceptor'
import { getCookie } from '../../../../utils/cookie'
import {
  CART_COLLECTION_TYPE,
  IApiCartItemRequestParameters,
  IApiGetCartResponse,
  ICartCollection,
  ICartItem,
  IIdentity,
} from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Cart'
import { ITransport } from '../../../Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Services/API/CMS'

const ENDPOINTS = {
  ADD_ITEMS: 'carts/addItems',
  ADD_ITEMS_BY_KEY_VALUE: 'carts/addItemsByKeyValue',
  ADD_ITEM_NO_COMPARISION: 'carts/addItemNoComparison',
  CART_COLLECTIONS: 'cartcollections',
  UPDATE_QUANTITY: 'carts/updateItemQuantity',
  UPDATE_QUANTITY_BY_KEY_VALUE: 'carts/updateItemQuantityByKeyValue',
  UPDATE_QUANTITY_BY_EXCLUDING_KEY: 'carts/updateItemQuantityByExcludingKey',
}

export interface IApiGetCartCollectionResponse {
  error: string
  data: ICartCollection[] | null
}

export interface IApiCartGenericRequestParameters {
  identity?: IIdentity
  populate: boolean
}

export interface IApiMetadataCartRequestParameters extends IApiCartGenericRequestParameters {
  productId: string
  quantity?: number
  metaData?: { [x: string]: any }
  keyPath?: string
}

const getSavedCartCollection = async (cartEmail: string): Promise<IApiGetCartCollectionResponse> => {
  try {
    const endpoint: string = `/${ENDPOINTS.CART_COLLECTIONS}?email=${cartEmail}&type=${CART_COLLECTION_TYPE.SAVE_FOR_LATER}`
    const response = await getDefaultCmsTransport(true).get(endpoint)
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}

const saveCartCollection = async (cartEmail: string, cartItems: ICartItem[]): Promise<IApiGetCartResponse> => {
  try {
    const endpoint: string = `/${ENDPOINTS.CART_COLLECTIONS}`
    const response = await getDefaultCmsTransport(true).post(endpoint, {
      type: CART_COLLECTION_TYPE.SAVE_FOR_LATER,
      email: cartEmail,
      items: mapItemsToSave(cartItems),
      uiCartId: getCookie('anonId', null) || '',
    })
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}

const mapItemsToSave = (cartItems: ICartItem[]) => {
  return cartItems.map((item: ICartItem) => {
    return {
      metaData: item.metaData,
      quantity: item.quantity,
      product: {
        sku: item.product.sku,
        id: item.product.id,
      },
    }
  })
}

const updateSavedCartCollection = async (
  id: string,
  cartEmail: string,
  cartItems: ICartItem[],
): Promise<IApiGetCartResponse> => {
  try {
    const endpoint: string = `/${ENDPOINTS.CART_COLLECTIONS}/${id}`
    const response = await getDefaultCmsTransport().put(endpoint, {
      type: CART_COLLECTION_TYPE.SAVE_FOR_LATER,
      email: cartEmail,
      items: mapItemsToSave(cartItems),
    })
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}

const deleteSavedCartCollection = async (id: string): Promise<IApiGetCartResponse> => {
  try {
    const endpoint: string = `/${ENDPOINTS.CART_COLLECTIONS}/${id}`
    const response = await getDefaultCmsTransport(true).delete(endpoint)
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}

const addItemsToCart = async (
  transport: ITransport,
  params: IApiCartItemRequestParameters[],
  identity: IIdentity,
  keyPath?: string,
): Promise<IApiGetCartResponse> => {
  try {
    const endpoint: string = keyPath ? `/${ENDPOINTS.ADD_ITEMS_BY_KEY_VALUE}` : `/${ENDPOINTS.ADD_ITEMS}`
    const response = await transport.post(endpoint, { params, ...identity, keyPath })
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}

const addItemToCartWithoutComparision = async (
  transport: ITransport,
  params: IApiCartItemRequestParameters,
): Promise<IApiGetCartResponse> => {
  try {
    const identity: any = {
      ...params.identity,
    }
    delete params.identity
    const endpoint: string = `/${ENDPOINTS.ADD_ITEM_NO_COMPARISION}`
    const response = await transport.post(endpoint, { ...params, ...identity })
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}

const updateItemQuantity = async (
  transport: ITransport,
  params: IApiMetadataCartRequestParameters,
  excludeRfid?: boolean,
): Promise<IApiGetCartResponse> => {
  try {
    const identity: any = {
      ...params.identity,
    }
    delete params.identity

    let endpoint = `/${ENDPOINTS.UPDATE_QUANTITY}`
    if (params.keyPath) {
      endpoint = excludeRfid
        ? `/${ENDPOINTS.UPDATE_QUANTITY_BY_EXCLUDING_KEY}`
        : `/${ENDPOINTS.UPDATE_QUANTITY_BY_KEY_VALUE}`
    }
    let response: any
    if (endpoint.includes(ENDPOINTS.UPDATE_QUANTITY_BY_EXCLUDING_KEY)) {
      response = await transport.put(endpoint, { ...params, ...identity })
    } else {
      response = await transport.post(endpoint, { ...params, ...identity })
    }
    return {
      error: '',
      data: response.data,
    }
  } catch (e) {
    return {
      error: 'An error has occurred...',
      data: null,
    }
  }
}

export default {
  updateSavedCartCollection,
  getSavedCartCollection,
  deleteSavedCartCollection,
  saveCartCollection,
  addItemsToCart,
  updateItemQuantity,
  addItemToCartWithoutComparision,
}
